<template>
  <section v-if="getCarouselList && getCarouselList.length">
    <HeaderPromo :caption="caption" />
    <SearchProduct class="mt-7" @add="addProduct" />

    <TopCtrlMenu v-if="$vuetify.breakpoint.mdAndUp" class="mt-7"
      :ctrlData="ctrlData"
      :elements="elements"
      :list="items" 
      @delete="deleteItem"
      @clearElements="clearAllElements"
    />

    <TopCtrlMenuMobile v-else class="mt-7"
      :ctrlData="ctrlData"
      :elements="elements"
      :list="items" 
      @delete="deleteItem"
      @clearElements="clearAllElements"
    />

    <section v-if="$vuetify.breakpoint.mdAndUp" class="list mt-5">
      <draggable :list="items"
        v-bind="dragOptions"
        @start="startMove($event)"
        @end="endMove($event, items)" >
          <transition-group>
            <PromoListItem v-for="(item, i) in items" :key="item.id"
              :item="item" 
              :listItems="items"
              :listID="elements" 
              class="app-card"
              :class="{ 'app-card_last': i === items.length - 1 }"
              @addRemoveID="addRemoveOneEl" 
            />
          </transition-group>
      </draggable>
    </section>

    <section v-else class="list mt-5">
      <draggable :list="items"
        v-bind="dragOptions"
        @start="startMove($event)"
        @end="endMove($event, items)" >
          <transition-group>
            <PromoListItemMobile v-for="(item, i) in items" :key="item.id"
              :item="item" 
              :listItems="items"
              :listID="elements" 
              class="app-card"
              :class="{ 'app-card_last': i === items.length - 1 }"
              @addRemoveID="addRemoveOneEl" 
            />
          </transition-group>
      </draggable>
    </section>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { generalPromo }           from '@/mixins/generalPromo'
import { mixinDraggable }         from '@/mixins/draggable'

import ChipsList     from '@/components/promo/ChipsListPromo'
import HeaderPromo   from '@/components/promo/HeaderPromo'
import TopCtrlMenu   from '@/components/promo/TopCtrlMenu'
import SearchProduct from '@/components/promo/SearchProduct'
import PromoListItem from '@/components/promo/PromoListItem'

import TopCtrlMenuMobile from '@/components/promo/TopCtrlMenuMobile'
import PromoListItemMobile from '@/components/promo/PromoListItemMobile'

export default {
  name: 'PromoCarousel',
  mixins: [generalPromo, mixinDraggable],
  components: { 
    ChipsList,
    HeaderPromo,
    TopCtrlMenu,
    SearchProduct,
    PromoListItem,
    TopCtrlMenuMobile,
    PromoListItemMobile,
  },
  data: () => ({}),
  computed: {
    ...mapGetters([ 
      'getCarouselList',
      'getCarouselItems', 
      'getProductsForCarousel',
    ]),
    amwayHost: function() {
      return process.env.VUE_APP_AMWAY;
    },
    catalogHost() {
      switch (this.$route.params.country) {
        case "ru":
          return "https://www.amway.ru";
        case "kz":
          return "https://www.kz.amway.com";
        case "ua":
          return "https://www.amway.ua";
      }
    },
    caption: function() {
      let allCarouselsInCountry = this.getCarouselList
        .find(c => c.code === this.$route.params.country).carousels;
      allCarouselsInCountry = allCarouselsInCountry
        .find(obj => obj.slug === this.$route.params.slug)
      return allCarouselsInCountry ? allCarouselsInCountry.name : 'Карусель';
    },
    articleList: function() {
      let arr = [];
      if ( this.getCarouselItems ) {
        this.getCarouselItems.forEach(item => {
          arr.push(item.article)
        })
      }
      return arr.join(' ');
    },
    carousel: function() {
      const carousels = this.getCarouselList
        .find(obj => obj.code === this.$route.params.country).carousels;
      return carousels.find(obj => obj.slug === this.$route.params.slug);
    },
    payload: function() {
      let obj = {};
      obj.country = this.$route.params.country;
      obj.queryString = this.articleList;
      obj.lang = this.$route.params.country;
      return obj;
    },
    items: function() {
      let arr = [];
      if ( this.getProductsForCarousel && this.getProductsForCarousel.length ) {
        this.getProductsForCarousel.forEach(product => {
          const trueProduct = this.getCarouselItems
            .find(obj => obj.article === product.alias);

          if ( trueProduct && Object.keys(trueProduct).length ) {
            const obj = {
              article: trueProduct.article,
              available_for: [],
              id: trueProduct.id,
              content_type: 'carousel',
              image: this.catalogHost + product.images[2].url,
              is_public: trueProduct.is_active,
              is_test: true,
              name: product.name,
              brandName: product.brandName,
              order: trueProduct.order,
              start_date: trueProduct.start_date,
              end_date: trueProduct.end_date,
              translates: [],
            };

            arr.push(obj);
          }
        })
      };
      arr.sort((a, b) => a.order > b.order ? 1 : -1);
      return arr;
    },
  },
  methods: {
    ...mapActions([
      'fetchAlerts',
      'fetchCarouselItems', 
      'addProductToCarousel',
      'searchProductsCarousel',
      'deleteProductFromCarousel',
    ]),
    async deleteItem(id) {
      await this.deleteProductFromCarousel([id]);
    },
    async addProduct(product) {
      const el = product[this.$route.params.country];
      if ( this.items.find(item => item.article === el.alias) ) {
        await this.fetchAlerts({
          alerts: [`Товар ${el.alias} уже добавлен в карусель.`],
          type: 'error'
        })
        return;
      }
      const payload = {
        article: el.alias,
        carousel: this.carousel.id,
      }
      
      await this.addProductToCarousel(payload);
      await this.searchProductsCarousel(this.payload);
    }
  },
  async created() {
    await this.fetchCarouselItems({ slug: this.$route.params.slug });
    await this.searchProductsCarousel(this.payload);
  },
  watch: {
    'ctrlData.isAll': function() {
      if ( this.ctrlData.isAll === 3 ) {
        this.addAllElements(this.items)
      } else if ( !!!this.ctrlData.isAll ) {
        this.clearAllElements()
      }
    },
    '$route.params': async function() {
      await this.fetchCarouselItems({ slug: this.$route.params.slug });
      await this.searchProductsCarousel(this.payload);
    },
  },
}
</script>

<style lang="scss" scoped>
.app-card {
  border-left: 1px solid #dadada !important;
  border-right: 1px solid #dadada !important;
  border-top: 1px solid #dadada !important;

  &_last {
    border-bottom: 1px solid #dadada !important;
  }
}
</style>
