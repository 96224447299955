import { mapActions } from 'vuex'
import draggable from 'vuedraggable'

export const mixinDraggable = {
  components: { draggable },
  data: () => ({
    drag: false, 
    disabled: false,
    firstDrag: false,
  }),
  computed: {
    dragOptions: function() {
      return {
          animation: 200,
          disabled: this.disabled,
          ghostClass: 'ghost',
          handle: '.handler',
      };
    },
  },
  methods: {
    ...mapActions(['changePromoOrder', 'changeOrderProductFromCarousel']),
    startMove() { this.drag = true; },

    async endMove(ev, list) { 
      const newIndx = ev.newIndex,
            oldIndx = ev.oldIndex;

      let elemWithDesiredOrder = {};
      
      if ( newIndx > oldIndx ) {
        elemWithDesiredOrder = list[newIndx - 1];
      } else if ( newIndx < oldIndx ) {
        elemWithDesiredOrder = list[newIndx + 1];
      } else {
        return
      }

      if ( this.$route.name === 'PromoList' ) {
        await this.changePromoOrder({
          id: list[newIndx].id,
          order: elemWithDesiredOrder.order
        })
      } else {
        await this.changeOrderProductFromCarousel({
          id: list[newIndx].id,
          order: elemWithDesiredOrder.order
        })
      }
      
    }
  }
}
