export const generalPromo = {
  data: () => ({
    elements: [],
    ctrlData: {
      isAll: false,
      inProd: false,
      inDraft: false,
      isChangeDateRange: false,
    },
  }),
  methods: {
    addAllElements(items) {
      items.forEach(el => {
        this.elements.push(el.id)
      })
    },
    addRemoveOneEl(id, items) {
      if ( id && this.elements.includes(id) ) {
        this.elements = this.elements.filter(el => el !== id);
        this.ctrlData.isAll = 2;
        this.$forceUpdate();
      } else if ( id ) {
        this.elements.push(id);
        if ( this.elements.length === items.length ) {
          this.ctrlData.isAll = 3;
        }
      }
    },
    clearAllElements() {
      this.elements = []
    },
  },
}
