<template>
  <section>
    <v-row class="mx-0 flex-nowrap ctrl flex-column flex-md-row">
      <div class="ma-0 d-relative">
        <div v-if="!item.is_public && !item.is_test" 
          class="ctrl__btn ml-md-3 ml-5 mr-5 mr-md-0 my-4 my-md-0"
          :class="{ 'ctrl__btn_disabled': !isValid }" 
          @click="sendNewStatus({ arr: [item.id], inTest: true })">
            <span>Проверить</span>
        </div>

        <div v-else-if="!item.is_public && item.is_test" 
          class="ctrl__btn ml-md-3 ml-5 mr-5 mr-md-0 my-4 my-md-0"
          :class="{ 'ctrl__btn_disabled': !isValid }" 
          @click="sendNewStatus({ arr: [item.id], inTest: true, inPublic: true })">
            <span>Опубликовать</span>
        </div>

        <div v-else-if="item.is_public && item.is_test" 
          class="ctrl__btn ml-md-3 ml-5 mr-5 mr-md-0 my-4 my-md-0" 
          @click="sendNewStatus({ arr: [item.id] })">
            <span>Снять с публикации</span>
        </div>
      </div>

      <div v-if="item.content_type !== 'carousel'"
        class="ctrl__btn ml-md-3 ml-5 mr-5 mr-md-0 my-4 my-md-0">
          <router-link :to="{
            name: item.content_type === 'promotion' ? 'PromoDetailPromo' : 'PromoDetailProduct',
            params: { country: $route.params.country, id: item.id }
            }"
            class="ctrl__btn"
          >
            Редактировать
          </router-link>
      </div>

      <div class="ctrl__btn ml-md-3 ml-5 mr-5 mr-md-0 my-4 my-md-0" @click="$emit('open')">
        <span>Удалить</span>
      </div>
    </v-row>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

import Confirm from '@/components/models/Confirm'

export default {
  name: 'ItemCtrlMenu',
  props: {
    item: {
      type: Object,
      require: true,
    },
    isValid: {
      type: Boolean,
      require: true,
    }
  },
  components: { Confirm },
  data: () => ({
    isOpenConfirm: false,
  }),
  methods: {
    ...mapActions([
      'changeStatus', 
      'changeStatusProductsFromCarousel',
    ]),
    async sendNewStatus({ arr, inTest = false, inPublic = false }) {
      if ( this.$route.name !== 'PromoCarousel' ) {
        const payload = {
          ids: JSON.stringify(arr),
          is_test: inTest,
          is_public: inPublic,
        }
        await this.changeStatus(payload);
      } else {
        const payload = {
          ids: JSON.stringify(arr),
          is_active: inPublic ? inPublic : false,
        }
        await this.changeStatusProductsFromCarousel(payload);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ctrl {
  &__btn {
    font-family: 'Source Sans Pro';
    font-size: 14px;
    line-height: 19px;
    color: #0a467e;
    text-decoration: none;
    cursor: pointer;
    transition: all .3s ease;

    &:hover {
      text-decoration: underline;
    }

    &_disabled {
      color: #bababa;
      pointer-events: none;
    }
  }
}

.overlay {
  position: fixed;
  display: grid;
  place-items: center;
  top: 0; left: 0;
  width: 100%; height: 100%;
  z-index: 10;
}
</style>
